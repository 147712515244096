<template>
  <v-container text-xs-center>
    <userDebug></userDebug>
    <v-row cols="3" v-if="loaded">
      <v-col cols="6">
      <Sparkline :config="gicleeConfig"></Sparkline>
      </v-col>
      <v-col cols="6">
      <Sparkline :config="productConfig"></Sparkline>
      </v-col>
      <v-col cols="12">
        <v-card>
          <pre>{{artworks}}</pre>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DateRange from '@/components/DateRange'
import SavedFilters from "@/components/widgets/SavedFilters";
import ArtistPromo from "@/components/widgets/ArtistPromo";
import InfoCard from "@/components/widgets/InfoCard";
import Sparkline from "@/components/widgets/Sparkline";
import QuickProductLinks from "@/components/widgets/QuickProductLinks";
import PopularArtworks from "@/components/widgets/PopularArtworks"
import BarChart from '../../../components/widgets/chart/Bar'
import LineChart from '../../../components/widgets/chart/Line'
import CustomArtistArtworks from "../../../api/customArtist/artwork";

export default {
  name: "Doricmor",
  components: {
    SavedFilters,
    InfoCard,
    QuickProductLinks,
    ArtistPromo,
    BarChart,
    LineChart,
    PopularArtworks,
    DateRange,
    Sparkline
  },
  data: () => ({
    commissionChartData: null,
    salesChartData: null,
    loaded: false,
    dateRange: null,
    artworks: null,
    gicleeConfig: {
      title: "Giclee Print Sales",
      labels: [],
      values: []
    },
    productConfig: {
      title: "Merchandise Sales",
      labels: [],
      values: []
    }
  }),
  async created() {
    const artworks = await CustomArtistArtworks.loadArtworks({})
    this.artworks = artworks.data
    // this.gicleeConfig.values = artworks.data.gicleedates?.map(i => i.count)
    // this.gicleeConfig.labels = artworks.data.gicleedates?.map((i,index) => {
    //   return index % 3 === 0 ? i.date.slice(-5) : ' '
    // })
    // this.productConfig.values = artworks.data.productdates?.map(i => i.count)
    // this.productConfig.labels = artworks.data.productdates?.map((i,index) => {
    //   return index % 3 === 0 ? i.date.slice(-5) : ' '
    // })
    this.loaded=true
  },
  methods: {
    setDateRange(dates) {
      artistReports.loadArtistArtworks({dateRange: dates}).then((results) => {
        this.artworks = results.data
      })
    },
    goToProducts(category) {
      const obj = {};
      obj.category = [category];
      this.$store.commit("filters/setFilters", obj);
      this.$router.push("/catalog").catch((err) => {
        return err !== null;
      });
    },
  },
  computed: {
    ...mapGetters({
      recentSearches: "filters/recentSearches",
      user: "user"
    }),
  },
};
</script>
