import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/customartist/artwork`

const loadArtworks = () => {
  return axios.get(api)
}


export default {
  loadArtworks,
}
